import moment from "moment";
import React, { Component } from "react";
import history from "../../../history";
import HeadingComponent from "./HeadingComponent";
import "./needHelpLists.css";
const MaterialService = require("../../../services/MaterialService");

class NeedListsHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            needListsHelp: [],
            filteredList: [],
            filter: "",
            showDropdown: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/requests/list").then((res) => {
            if (res.status) {
                this.setState({
                    needListsHelp: res.data,
                    filteredList: res.data,
                });
            } else {
                alert(res.message || "Something Went Wrong");
            }
        });
    };

    onInputChange = (event, id) => {
        const { value } = event.target;

        this.updateStatus(id, value);
    };

    handleFilterClick = () => {
        this.setState((prevState) => ({
            showDropdown: !prevState.showDropdown,
        }));
    };

    handleFilterChange = (filter) => {
        this.setState({ filter, showDropdown: false }, () =>
            this.applyFilter()
        );
    };

    applyFilter = () => {
        const { needListsHelp, filter } = this.state;

        const filteredList = filter
            ? needListsHelp.filter((item) => item.status === filter)
            : needListsHelp;

        this.setState({ filteredList });
    };

    updateStatus = (id, status) => {
        MaterialService.updateStatus(id, status).then((res) => {
            if (!res.status) {
                alert(res.message || "Status update failed");
            } else {
                this.fetchData();
            }
        });
    };

    render() {
        const { filteredList, showDropdown, filter } = this.state;

        return (
            <div id="needHelplists" className="need-help-list-main-container">
                <HeadingComponent
                    mainTitle="Need Help"
                    title="View Banners"
                    path="/material-manager/need-help-requests-list"
                    titleTwo="create need help"
                    pathTwo="/material-manager/need-help"
                />
                <div className="main-container">
                    <div className="main-need-help-heading">
                        Need help - Requesting Listing Page
                    </div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading">
                            Requested on
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Full Name
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Email
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Mobile
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Type
                        </div>
                        <div
                            className="col-md-1 no-padding row-heading cursor-pointer position-relative"
                            onClick={this.handleFilterClick}
                        >
                            Status
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginLeft: "10px" }}
                            ></i>
                            {showDropdown && (
                                <div className="filter-dropdown">
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange("")
                                        }
                                    >
                                        All
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "Relocating"
                                            )
                                        }
                                    >
                                        Relocating
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "SeniorCitizen"
                                            )
                                        }
                                    >
                                        Senior Citizen
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "House Declutter"
                                            )
                                        }
                                    >
                                        House Declutter
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                    <div className="col-md-12 row no-padding">
                        {filteredList.length > 0 ? (
                            filteredList.map((lists) => (
                                <div
                                    key={lists.id}
                                    className="col-md-12 row no-padding margin-top need-help-details-contents"
                                >
                                    <div className="col-md-2 no-padding">
                                        {moment(lists.requestedDate).format(
                                            "DD-MMM-YYYY"
                                        )}
                                    </div>
                                    <div className="col-md-2 no-padding">
                                        {lists.fullName}
                                    </div>
                                    <div className="col-md-2 no-padding">
                                        {lists.email}
                                    </div>
                                    <div className="col-md-2 no-padding">
                                        {lists.phone}
                                    </div>
                                    <div className="col-md-2 no-padding">
                                        {lists.status}
                                    </div>
                                    <div className="col-md-1 no-padding">
                                        <select
                                            className="select-droopdown"
                                            name="status"
                                            value={lists.status}
                                            onChange={(e) =>
                                                this.onInputChange(e, lists.id)
                                            }
                                        >
                                            <option value="Relocating">
                                                Relocating
                                            </option>
                                            <option value="SeniorCitizen">
                                                Senior Citizen
                                            </option>
                                            <option value="House Declutter">
                                                House Declutter
                                            </option>
                                        </select>
                                    </div>
                                    <div className="col-md-1 no-padding">
                                        <i
                                            className="fas fa-pencil-alt banner-edit-button"
                                            aria-hidden="true"
                                            onClick={() => {
                                                history.push(
                                                    `/material-manager/need-help`
                                                );
                                            }}
                                            style={{
                                                color: "#DA4C76",
                                                marginLeft: "30px",
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                className="alert alert-warning"
                                style={{
                                    width: "max-content",
                                    height: "50px",
                                    textAlign: "center",
                                    margin: "auto",
                                }}
                            >
                                No Data Found
                            </div>
                        )}
                    </div>
                    <div className="btn-section">
                        <button>Cancel</button>
                        <button>Download</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default NeedListsHelp;
