import React, { Component, lazy } from "react";
import { Switch } from "react-router-dom";
import AppRoute from "../../../routes/AppRoute";
import DashboardLayout from "../../../routes/Layouts/DashboardLayout";
import CreateBanner from "./AddMaterialBanner";
import AddPartner from "./AddPartner";
import SuccessStory from "./AddSuccessStory";
import CreateNeedHelp from "./CreateNeedHelp";
import DonationRequestsLists from "./DonationRequestsLists";
import DropOffLocationsList from "./DropOffLocationsList";
import DropOffRequestsDetails from "./DropOffRequesterDetails";
import DropOffSetup from "./DropOffSetup";
import EditBanner from "./EditBanner";
import MaterialsManagerDashBoard from "./MaterailsManagerDashBorad";
import MaterialRequestsDetails from "./MaterialRequestsDetails";
import BannersList from "./MaterialsBannerList";
import MaterialsRequestLists from "./MatrialsRequestsLists";
import NeedListsHelp from "./NeedHelpLists";
import NeedHelpRequestsList from "./NeedHelpRequests";
import PartnersLists from "./PartnersLists";
import SuccessStoriesLists from "./SuccessStoriesLists";
import SuccessStoryEdit from "./SuccessStoryEdit";
import VolunteerLists from "./VolunteerLists";
import VolunteerSetup from "./VolunteerSetup";
class MaterialManager extends Component {
    constructor(props) {
        super(props);
    }
    state = {};
    render() {
        return (
            <>
                <Switch>
                    <AppRoute
                        exact
                        path={`${this.props.match.path}`}
                        component={MaterialsManagerDashBoard}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/banners-list`}
                        component={BannersList}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/ngo-requests-list`}
                        component={MaterialsRequestLists}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/material-requests-details/:id`}
                        component={MaterialRequestsDetails}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/edit-banner/:id`}
                        component={EditBanner}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/add-material-banners`}
                        component={CreateBanner}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/drop-off-location-lists`}
                        component={DropOffLocationsList}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/drop-off-setup`}
                        component={DropOffSetup}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/drop-off-update/:id`}
                        component={DropOffSetup}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/volunteer-lists`}
                        component={VolunteerLists}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/need-help-requests-list`}
                        component={NeedHelpRequestsList}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/volunteer-setup`}
                        component={VolunteerSetup}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/volunteer-setup/:id`}
                        component={VolunteerSetup}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/donation-requests-list`}
                        component={DonationRequestsLists}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    {/* <AppRoute
                        exact
                        path={`${this.props.match.path}/material-requests-list`}
                        component={MaterialsRequestLists}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    /> */}
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/partners-list`}
                        component={PartnersLists}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/add-partner`}
                        component={AddPartner}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/add-partner/:id`}
                        component={AddPartner}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/donation-request/detail/:id`}
                        component={DropOffRequestsDetails}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />

                    <AppRoute
                        exact
                        path={`${this.props.match.path}/need-help-lists`}
                        component={NeedListsHelp}
                        config={{ fullscreen: true }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/success-stories-lists`}
                        component={SuccessStoriesLists}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/need-help`}
                        component={CreateNeedHelp}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/add-success-story`}
                        component={SuccessStory}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                    <AppRoute
                        exact
                        path={`${this.props.match.path}/update-success-story/:id`}
                        component={SuccessStoryEdit}
                        config={{ fullscreen: false }}
                        layout={DashboardLayout}
                    />
                </Switch>
            </>
        );
    }
}

export default MaterialManager;
